import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import googleLogo from './../../Assets/googleicon.svg';
import googleButton from './../../Assets/btn_google_signin_dark_normal_web@2x.png';
import googleButtonHover from './../../Assets/btn_google_signin_dark_focus_web@2x.png';
import config from '../../config';

const useStyles = makeStyles((theme) => ({
  signupButton: {
    margin: '10px 0px 30px 0px',
    width: '220px !important',
    fontWeight: 'bold',
    margin: '0 auto',
    display: 'block'
  },
  subHeader: {
    textAlign: 'left',
    width: '100%',
    margin: '0px 0px 0px 10px'
  },
  header: {
    marginTop: '0px'
  },
  signupContainer: {
    width: '300px !important',
    paddingTop: '75px'
  },
  googleLogo: {
    backgroundColor: 'transparent',
    margin: '10px 10px 10px 0px'
  },
  signupLink: {
    margin: '10px 10px 30px 10px'
  }
}));

export default function Signup() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className={classes.signupContainer}
      >
        <h4 className={classes.subHeader}>
          Welcome back
        </h4>          
        <h1 className={classes.header}>
          Login to your account
        </h1>
          <a href={config.loginUrl} className={`width-100 ${classes.signupLink}`}>
            <img src={googleButton} className={classes.signupButton} alt="zoom-icon"
              onMouseOver={e => (e.currentTarget.src = googleButtonHover)}
              onMouseOut={e => (e.currentTarget.src = googleButton)} />
          </a>
          <Grid container className="text-center width-100 display-block">
            <Grid item>
              <Link href={config.loginUrl} variant="body2" className="text-center">
                {"Don't have an account? Join free today"}
              </Link>
            </Grid>
          </Grid>
      </Box>
    </Container>
  );

}