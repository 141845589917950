import * as React from 'react';
import config from '../config';
import { styled } from '@material-ui/core/styles';
import MuiAppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  logoutButton: {
    textColor: 'color: rgba(84, 84, 84) !important',
    position: 'relative !important',
    top: '5px'
  }
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export default function TopNav(props)  {
  const classes = useStyles();  

  const logOut = () => {
    if (typeof window !== 'undefined') {
       window.location.href = config.onLogoutUrl;
    }
  };

  return (
    <AppBar position="absolute" open={props.open} className="text-gray background-white border-light">
      <Toolbar
        sx={{
          pr: '24px',
        }}
      >
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={props.toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(props.open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <div>
              <Button className={classes.logoutButton} onClick={() => logOut()}>Log out</Button>
            </div>
          </Grid>
        </Grid>    
      </Toolbar>
    </AppBar>
  );
}
