import fetch from 'node-fetch';
import config from '../config';

const getReport = async (meetingId) => {
  let url = `${config.getReportUrl.replace('meetingId', meetingId)}`;
  const meeting = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(meeting.status);
  const response = await meeting.text();
  return response;
};

const getMeeting = async (meetingId) => {
  let url = `${config.getMeetingUrl.replace('meetingId', meetingId)}`;
  const meeting = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(meeting.status);
  const response = await meeting.json();
  return response;
};

const getPublicMeeting = async (publicKey) => {
  let url = `${config.getPublicMeetingUrl.replace('publicKey', publicKey)}`;
  const meeting = await fetch(url);
  validateResponseStatus(meeting.status);
  const response = await meeting.json();
  return response;
};

const getMeetings = async (nextPageToken, meetingType = 'upcoming') => {
  let url = `${config.getMeetingsUrl}?meetingType=${meetingType}`;
  if (nextPageToken) {
    url += `&nextPageToken=${nextPageToken}`
  }
  const meetings = await fetch(url, {
    credentials: 'include'
  });
  validateResponseStatus(meetings.status);
  const response = await meetings.json();
  return response;
};

const getSettings = async () => {
  const settings = await fetch(config.settingsUrl, {
    credentials: 'include'
  });
  validateResponseStatus(settings.status);
  return await settings.json();
};

const getUser = async () => {
  const settings = await fetch(config.getUserUrl, {
    credentials: 'include'
  });
  validateResponseStatus(settings.status);
  return await settings.json();
};

const updateSettings = async (settingsObj) => {
  const update = await fetch(config.settingsUrl, {
    method: 'PATCH',
    body: JSON.stringify(settingsObj),
    credentials: 'include'
  });
  validateResponseStatus(update.status);
  return await update.text();
};

const disableProcessing = async (meetingId) => {
  const url = config.disableProcessingUrl.replace('meetingId', encodeURIComponent(meetingId));
  const update = await fetch(url, {
    method: 'PATCH',
    credentials: 'include'
  });
  validateResponseStatus(update.status);
  return await update.text();
};

const enableProcessing = async (meetingId, params) => {
  const url = config.enableProcessingUrl.replace('meetingId', encodeURIComponent(meetingId));
  const update = await fetch(url, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify({
      meetingType: params.meetingType, 
      jobFunction: params.jobFunction, 
      jobRole: params.jobRole
    })
  });
  validateResponseStatus(update.status);
  return await update.text();
};

const validateResponseStatus = (statusCode) => {
  if (statusCode === 403 && process.env.NODE_ENV !== 'development') {
    window.location.href = config.loginUrl;
  }
};

const getTranscriptString = async (downloadUrl) => {
  const response = await fetch(downloadUrl, {
    method: 'GET'
  });
  validateResponseStatus(response.status);
  return await response.text();
};

const _exports = {getMeeting, getMeetings, getReport, getSettings, getUser, getTranscriptString, getPublicMeeting, disableProcessing, enableProcessing, updateSettings};
export default _exports;
