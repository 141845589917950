import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import { styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import MuiDrawer from '@material-ui/core/Drawer';
import Overview from './Overview';
import Meetings from './Meetings';
import MeetingPage from './MeetingPage';
import PublicReport from './PublicReport';
import Signup from './Signup';
import { SideNav } from './SideNav';
import Toolbar from '@material-ui/core/Toolbar';
// import Badge from '@material-ui/core/Badge';
import TopNav from './TopNav';
import Yobs from './../Services/Yobs';
import yobsLogo from './../Assets/logo.svg';

const drawerWidth = 240;
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const useStyles = makeStyles((theme) => ({
  yobsLogo: {
    margin: '30px 0px 30px 0px'
  }
}));

function DashboardContent() {
  const classes = useStyles();
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  if (location.pathname.startsWith("/report/")) {
    return (
      <PublicReport />
    );
  }
  if (location.pathname.startsWith("/signup")) {
    return (
      <Signup />
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopNav toggleDrawer={toggleDrawer} open={open}/>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <img src={yobsLogo} className={`width-50 ${classes.yobsLogo}`} alt="yobs-logo"/>
        </Toolbar>
        <Divider />
        <List>{SideNav}</List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Switch>
            <Route path="/overview">
              <Overview />
            </Route>
            <Route path="/meetings" component={Meetings}>
            </Route>
            <Route path="/meeting/:meetingId" component={MeetingPage}>
            </Route>            
            <Route exact path="/" render={() => (
                <Redirect to="/overview"/>
            )}/>
          </Switch>        
        </Container>
      </Box>
    </Box>
  );
}

export default function Dashboard() {
  return (
    <Router>
      <DashboardContent />
    </Router>
  );
}
