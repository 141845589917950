import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import config from '../../config';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import utils from './../utils';
import Yobs from './../../Services/Yobs';
import { makeStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import EventIcon from '@material-ui/icons/Event';
import ScheduleIcon from '@material-ui/icons/Schedule';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ReactPlayer from 'react-player'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import TabPanel from './../../Components/TabPanel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: '725px',
    fontSize: '0.8rem',
    margin: '0px 0px 15px 0px'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: 'black',
    backgroundColor: 'initial',
    padding: '0px'
  },
  emailRecipients: {
    overflowWrap: 'break-word'
  },
  icon: {
    float: 'left',
    marginRight: '5px',
    position: 'relative',
    bottom: '3px'
  },
  copyLink: {
    float: 'right',
    position: 'relative',
    top: '15px',
    border: '1px solid salmon'
  },
  downloadLink: {
    float: 'right',
    border: '1px solid salmon',
    width: '100px',
    margin: '13px 10px 10px 10px'
  },
  contentContainer: {
    boxShadow: 'initial !important',
    backgroundColor: 'initial !important',
    color: 'black !important'
  },
  reportHeader: {
    fontSize: '1rem',
    fontWeight: 'initial',
    margin: '20px 25px 20px 25px',
    fontWeight: '500',
    lineHeight: '1.75',
    whiteSpace: 'normal',
    letterSpacing: '0.02857em',
    width: '100px'
  },
  innerContentContainer: {
    height: 'auto',
    overflow: 'scroll',
    padding: '10px 20px 10px 20px',
    maxHeight: '600px'
  },
  mediaContainerHr: {
    borderColor: 'rgba(200,200,200,0.1)',
    bottom: '1px',
    position: 'relative',
    margin: '0px'
  },
  tab: {
    margin: '10px 0px 10px 0px'
  },
  loaderContainer: {
    position: 'relative',
    top: '70px'
  },
  pdfLoaderStyle: {
    height: '100px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  title: {
    fontSize: 14,
  },
  transcriptSegment: {
    display: 'flex',
    margin: '0px 0px 20px 0px'
  },
  transcriptIcon: {
    marginRight: '10px',
    position: 'relative',
    bottom: '2px'
  },
  transcriptContainer: {
    maxHeight: '600px',
    overflow: 'scroll'
  }
}));

function MeetingPageContent({props}) {
  const { meetingId } = useParams();
  const classes = useStyles();
  const [meeting, setMeeting] = useState('loading');
  const [videoUrl, setVideoUrl] = useState(null);
  const [transcript, setTranscript] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const publicView = (props.publicKey && !meetingId) ? true : false;
  const reportUrl = (publicView) ? 
    `${config.getPublicMeetingReportUrl.replace('publicKey', props.publicKey)}` :
    `${config.getReportUrl.replace('meetingId', meetingId)}`;
  const pdfParams = {url: reportUrl, withCredentials: true};
  const pdfReport = React.useMemo( () => (pdfParams), [undefined]);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);


  useEffect( () => {
    async function publicViewFetchData() {
      const data = await Yobs.getPublicMeeting(props.publicKey);
      setStateFromData(data);
    }
    async function fetchData() {
      const data = await Yobs.getMeeting(meetingId);
      setStateFromData(data);
    }
    async function setSegment() {
      const getUserResponse = await Yobs.getUser();
      window.analytics.identify(getUserResponse.email, {
        name: getUserResponse.object.name,
        email: getUserResponse.object.email,
      });
      window.analytics.page()
    }
    function setStateFromData(data) {
      setMeeting(data.object.meeting);
      if (data.object.videoUrls.length) {
        setVideoUrl(data.object.videoUrls[0]);
      }
      if (data.object.transcripts.length) {
        setTranscript(data.object.transcripts[0]);
      }
    }
    if (publicView) {
      publicViewFetchData();
    } else {
      fetchData();
      setSegment();
    }
  }, []);

  if (meeting === 'loading') {
    return (
      <div className={classes.loaderContainer}>
        <Grid container spacing={12} className="main-content">
          <Grid item xs={12}>
              <CircularProgress />
          </Grid>
        </Grid>
      </div>
    );    
  }
  const handleClose = (event, reason) => {
    setSnackbarOpen(false);
  };

  const MeetingInfoBar = () => (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {
          meeting.emailRecipients && (
            <Grid item xs={'auto'}>
              <Paper className={`${classes.paper} ${classes.emailRecipients}`}>
                <MailOutlineIcon className={`${classes.icon} text-navy`}/>  
                {meeting.emailRecipients}
              </Paper>
            </Grid>
          )
        }
        <Grid item xs={'auto'}>
          <Paper className={classes.paper}>
            <WorkOutlineIcon className={`${classes.icon} text-navy`} /> 
            {utils.meetingFunctionMap(meeting.jobFunction)}
          </Paper>
        </Grid>
        <Grid item xs={'auto'}>
          <Paper className={classes.paper}>
            <InsertDriveFileOutlinedIcon className={`${classes.icon} text-navy`} />
            {utils.meetingRoleMap(meeting.jobRole)}
          </Paper>
        </Grid>
        {
          meeting.startTime &&
          <React.Fragment>
            <Grid item xs={'auto'}>
              <Paper className={classes.paper}>
                <EventIcon className={`${classes.icon} text-navy`}/> {utils.isoDateToHumanReadable(meeting.startTime)}
              </Paper>
            </Grid>
            <Grid item xs={'auto'}>
              <Paper className={classes.paper}>
                <ScheduleIcon className={`${classes.icon} text-navy`} /> {utils.isoDateToLocalTime(meeting.startTime)}
              </Paper>
            </Grid>
          </React.Fragment>
        }
      </Grid>
    </div>
  );

  if (meeting.status === 'WAITING') {
    return (
      <div>
        <Grid container spacing={1} className="main-content">
          <Grid item xs={6}>
            <h1 className={classes.meetingTitle}> {meeting.topic} </h1>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <MeetingInfoBar />
          </Grid>
          <Grid item xs={6}>
            <h4> This meeting is still waiting to be recorded. </h4>
          </Grid>
        </Grid>
      </div>      
    );
  }

  if (meeting.status === 'PROCESSING') {
    return (
      <div>
        <Grid container spacing={1} className="main-content">
          <Grid item xs={6}>
            <h1 className={classes.meetingTitle}> {meeting.topic} </h1>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <MeetingInfoBar />
          </Grid>
          <Grid item xs={6}>
            <h4> We are still processing this meeting. </h4>
          </Grid>
        </Grid>
      </div>      
    );    
  }

  if (meeting.status === 'FAILED') {
    return (
      <div>
        <Grid container spacing={1} className="main-content">
          <Grid item xs={6}>
            <h1 className={classes.meetingTitle}> {meeting.topic} </h1>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <MeetingInfoBar />
          </Grid>
          <Grid item xs={6}>
            <h4> We failed to process this meeting. </h4>
          </Grid>
        </Grid>
      </div>      
    );
  }

  function MediaContainer() {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      <Grid item xs={12} md={6} lg={6}>
        <Paper
          xs={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240
          }}
        >
        <AppBar position="static"  className={classes.contentContainer}>
          <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Video" className={classes.tab}/>
            <Tab label="Transcript" className={classes.tab} />
          </Tabs>
        </AppBar>
        <hr className={classes.mediaContainerHr}/>
        <div>
          <TabPanel value={value} index={0}>
            { videoUrl && <ReactPlayer url={videoUrl} controls={true} width={'100%'} height={'auto'} /> }
          </TabPanel>
          <TabPanel value={value} index={1} className={classes.transcriptContainer}>
            {
              transcript && transcript.map( (segment) => (
                <div className={classes.transcriptSegment}>
                  <div>
                    <AccountCircleIcon className={`${classes.transcriptIcon} text-navy`}/>
                  </div>
                  <div>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {segment.speaker} - {segment.timestamp}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {segment.transcript}
                    </Typography>                
                  </div>
                </div>
              ))
            }
          </TabPanel>
        </div>
        </Paper>
      </Grid>
    );
  };

  const copyLink = (publicKey) => {
    const publicUrl = `https://zoom.yobs.io/report/${publicKey}`;
    utils.copyTextToClipboard(publicUrl);
    setSnackbarOpen(true);
  }

  return (
    <div>
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      message={'Link has been copied to clipboard'}
    />
    <Grid container spacing={1} className="main-content">
      <Grid item xs={6}>
        <h1 className={classes.meetingTitle}> {meeting.topic} </h1>
      </Grid>
      <Grid item xs={6}>
        {
          !publicView && (
            <Button className={`${classes.copyLink} text-salmon`} onClick={() => copyLink(meeting.publicKey)}> Copy link </Button>
          )
        }
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <MeetingInfoBar />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <AppBar position="static"  className={classes.contentContainer}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <h2 className={classes.reportHeader}> Report </h2>
              </Grid>
              <Grid item xs={6}>
                <a href={`${config.getReportUrl.replace('meetingId', meetingId)}`} target="_blank">
                {
                  !publicView && (
                    <Button className={`${classes.downloadLink} text-salmon`}> Download </Button>
                  )
                }
                </a>
              </Grid>
            </Grid>
            <hr className={classes.mediaContainerHr}/>
            <div className={classes.innerContentContainer}>
              <Document file={pdfReport} 
                loading={(
                  <div className={classes.pdfLoaderStyle}>
                    <div>
                      <CircularProgress />
                      <span>Downloading your document... (might take a while)</span>
                    </div>
                  </div>
                )}              
                onLoadSuccess={({ numPages })=>setNumPages(numPages)}>
                {Array.apply(null, Array(numPages))
                .map((x, i)=>i+1)
                .map((page, i) => <Page key={i} pageNumber={page}/>)}
              </Document>
            </div>
          </AppBar>
        </Paper>
      </Grid>
      <MediaContainer />
      <Grid item xs={12} md={12} lg={12} className="main-content-container">
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
        </Paper>
      </Grid>
    </Grid>
    </div>
  );
}

export default function MeetingPage(props) {
  return <MeetingPageContent props={props}/>;
}