import * as React from 'react';
import {
  NavLink
} from "react-router-dom";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PieChartIcon from '@material-ui/icons/PieChart';
import VideoCallIcon from '@material-ui/icons/VideoCall';

export const SideNav = (
  <div>
    <NavLink to="/overview" activeClassName="text-salmon background-light-salmon" className="sideNavLink">
      <ListItem button className="text-inherit">
        <ListItemIcon className="text-inherit">
          <PieChartIcon className="text-inherit" style={{'position':'relative','left': '6px'}}/>
        </ListItemIcon>
        <ListItemText primary="Overview" disableTypography={true} />
      </ListItem>
    </NavLink>

    <NavLink to="/meetings" activeClassName="text-salmon background-light-salmon" className="sideNavLink">
      <ListItem button className="text-inherit">
        <ListItemIcon className="text-inherit">
          <VideoCallIcon className="text-inherit" style={{'position':'relative','left': '6px'}}/>
        </ListItemIcon>
        <ListItemText primary="Meetings" disableTypography={true} />
      </ListItem>
    </NavLink> 
  </div>
);

