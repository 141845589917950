import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MeetingList from './MeetingList';
import Paper from '@material-ui/core/Paper';
import Yobs from './../../Services/Yobs';

function MeetingsContent() {
  const [meetings, setMeetings] = useState(['loading']);
  const [nextPageToken, setNextPageToken] = useState(null);

  useEffect( () => {
    async function fetchData() {
      const data = await Yobs.getMeetings(undefined, 'scheduled');
      setMeetings(data.object.meetings);
      if (data.object.next_page_token === "") {
        setNextPageToken("none");
      } else {
        setNextPageToken(data.object.next_page_token);
      }
      const getUserResponse = await Yobs.getUser();
      window.analytics.identify(getUserResponse.email, {
        name: getUserResponse.object.name,
        email: getUserResponse.object.email,
      });
      window.analytics.page()
    }
    fetchData();
  }, []);


  const getMeetings = async () => {
    if (nextPageToken === "none") {
      return;
    }
    const data = await Yobs.getMeetings(nextPageToken, 'scheduled');
    const concatMeetings = meetings.concat(data.object.meetings);
    setMeetings(concatMeetings);
    if (data.object.next_page_token === "") {
      setNextPageToken("none");
    } else {
      setNextPageToken(data.object.next_page_token);
    }
  };
  const shouldShowMoreButton = (meetings[0] !== 'loading' && nextPageToken !== "none")
  return (
    <div>
    <Grid container spacing={3} className="main-content">
      <Grid item xs={12} md={12} lg={12}>
        <h1 className="text-navy"> Meetings </h1>
      </Grid>
      <Grid item xs={12} md={12} lg={12} className="main-content-container">
        <Paper
          className="background-initial"
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          <MeetingList data={meetings} />
        </Paper>
        {
          shouldShowMoreButton && (
            <Button onClick={() => getMeetings()} sx={{ mt: 3 }}>
              See more meetings
            </Button>
          )
        }
      </Grid>
    </Grid>
    </div>
  );
}

export default function Meetings() {
  return <MeetingsContent />;
}