import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import zoomLogo from './../../Assets/zoom-logo.png';
import MeetingSettings from './MeetingSettings';
import Link from '@material-ui/core/Link';
import utils from './../utils';

const useStyles = makeStyles((theme) => ({
  zoomIcon: {
    width: '40px',
    margin: '20px 0px 20px 10px'
  },
  tableRow: {
    border: '2px solid rgb(20 20 20 / 3%) !important',
    borderRadius: '5px',
    height: '100px',
    outline: '20px solid rgba(250,250,250,1) !important',
    margin: '10px',
    display: 'inline-block',
    backgroundColor: 'white',
    width: '100%'
  },
  zoomIconRow: {
    paddingRight: '0px !important'
  },
  settingsRow: {
    width: '100%',
    paddingRight: '0px !important',
    display: 'block'
  },
  rowText: {
    display: 'block',
    margin: '0px 0px 5px 0px',
    color: 'rgba(84, 84, 84) !important'
  },
  rowTextBold: {
    fontFamily: 'PlusJakartaSans-Bold',
    display: 'block',
    margin: '0px 0px 5px 0px'    
  },
  tableContainer: {
    minWidth: '552px',
    backgroundColor: '#fafafa',
    position: 'relative',
    right: '10px',
    bottom: '10px'
  },
  floatRightCell: {
    float: 'right',
    position: 'relative',
    bottom: '75px',
    paddingRight: '75px !important'
  },
  settings_opened_false: {
    display: 'none'
  },
  settings_opened_true: {
    display: 'inline-block',
    width: '100%',
    backgroundColor: 'white',
    margin: '10px 10px 0px 10px',
    position: 'relative',
    top: '-25px',
    border: '2px solid rgb(20 20 20 / 3%)'
  },
  editLink_opened_true: {
    cursor: 'pointer',
    position: 'relative',
    left: '59px',
    bottom: '15px'
  },
  recordBtn_opened_true: {
    position: 'relative',
    bottom: '7px',
    fontWeight: 'bold'
  },
  editLink_opened_false: {
    display: 'none'
  },
  recordBtn_opened_false: {
    fontWeight: 'bold'
  }
}));

export default function UpcomingCalls(props) {
  const classes = useStyles();
  const [localState, setLocalState] = useState({});

  if (props.data.length === 0) {
    return (
      <React.Fragment>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <h3 className="text-center"> No upcoming calls </h3>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }

  if (props.data[0] === 'loading') {
    return (
      <React.Fragment>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>
                <CircularProgress className="text-center"/>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );    
  }

  const handleSwitchChange = (checked, meetingUUId, index) => {
    if (checked === false) {

      // You can uncheck this switch in two scenarios:
      // 1. Template selection is opened, and you uncheck this switch just to exit out of template selection
      // 2. Template selection is closed, and you're unchecking this switch to disable processing on a meeting

      if (localState[meetingUUId]) {
        // If template selection is opened...
        const modifyLocalState = Object.assign({}, localState);
        modifyLocalState[meetingUUId] = false;
        setLocalState(modifyLocalState);
        return;
      } else {
        // Else if template selection is closed...
        return props.toggleYobsProcessing(checked, meetingUUId, index);
      }
    }
    const modifyLocalState = Object.assign({}, localState);
    modifyLocalState[meetingUUId] = true;
    setLocalState(modifyLocalState);
  };
  const enableYobsProcessing = async (meetingUUId, index, params) => {
    const objectParams = {
      meetingType: params[0],
      jobFunction: params[1],
      jobRole: params[2]
    };
    props.toggleYobsProcessing(true, meetingUUId, index, objectParams);
    if (Object.values(params).includes('')) {
      return;
    }
    const modifyLocalState = Object.assign({}, localState);
    modifyLocalState[meetingUUId] = false;
    setLocalState(modifyLocalState);
  }

  const editMeeting = (meetingUUId) => {
    const modifyLocalState = Object.assign({}, localState);
    modifyLocalState[meetingUUId] = true;
    setLocalState(modifyLocalState);
  };

  return (
    <React.Fragment>
      <Table size="small" className={classes.tableContainer}>
        <TableBody>
          {  props.data.map((meeting, index) => (
            <React.Fragment key={index}>
              <TableRow key={index} className={`${classes.tableRow}`}>
                <TableCell key={index} className={classes.zoomIconRow}>
                  <img src={zoomLogo} className={classes.zoomIcon} alt="zoom-icon"/>
                </TableCell>
                <TableCell>
                  <span className={classes.rowTextBold}>
                    {meeting.topic}
                  </span>
                  <span className={classes.rowText}>
                  {utils.parseIsoDateTime(meeting.start_time, meeting.duration)}
                  </span>
                </TableCell>
                <TableCell className={classes.floatRightCell}>
                  <Switch
                    size="medium"
                    checked={(localState[meeting.uuid] || meeting.SHOULD_PROCESS)}
                    onChange={(ev) => handleSwitchChange(ev.target.checked, meeting.uuid, index)}
                  />
                  <span className={classes[`recordBtn_opened_${String(meeting.SHOULD_PROCESS || false)}`]}> 
                    Record & Analyze 
                  </span>
                  <Link className={classes[`editLink_opened_${String(meeting.SHOULD_PROCESS || false)}`]} onClick={() => editMeeting(meeting.uuid)}> 
                    Edit 
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow className={classes[`settings_opened_${String(localState[meeting.uuid] || false)}`]}>
                <TableCell className={classes.settingsRow}>
                  <MeetingSettings enableYobsProcessing={enableYobsProcessing} meetingUUId={meeting.uuid} index={index}/>
                </TableCell>
              </TableRow>            
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
