module.exports = {
  "getMeetingUrl": "https://api.zoom.yobs.io/v1/api/meetings/meetingId",
  "getMeetingsUrl": "https://api.zoom.yobs.io/v1/api/meetings",
  "getReportUrl": "https://api.zoom.yobs.io/v1/api/meetings/meetingId/report",
  "getPublicMeetingUrl": "https://api.zoom.yobs.io/v1/api/public-meeting/publicKey",
  "getPublicMeetingReportUrl": "https://api.zoom.yobs.io/v1/api/public-meeting/publicKey/report",
  "getUserUrl": "https://api.zoom.yobs.io/v1/api/user",
  "enableProcessingUrl": "https://api.zoom.yobs.io/v1/api/meetings/meetingId/enable-processing",
  "disableProcessingUrl": "https://api.zoom.yobs.io/v1/api/meetings/meetingId/disable-processing",
  "settingsUrl": "https://api.zoom.yobs.io/v1/api/settings",
  "onLogoutUrl": "https://yobstech.com",
  "loginUrl": "https://api.zoom.yobs.io/v1/api/oauth/google"
};