import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Settings from './Settings';
import UpcomingCalls from './UpcomingCalls';
import Yobs from './../../Services/Yobs';

function OverviewContent() {
  const [meetings, setMeetings] = useState(['loading']);
  const [settings, setSettings] = useState({});
  const [nextPageToken, setNextPageToken] = useState(null);
  const [user, setUser] = useState({});
  const [snackbarText, setSnackbarText] = React.useState('');
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  useEffect( () => {
    async function fetchData() {
      const data = await Promise.all([
        Yobs.getMeetings(),
        Yobs.getSettings(),
        Yobs.getUser()
      ]);
      const getMeetingsResponse = data[0].object;
      setMeetings(getMeetingsResponse.meetings);
      if (getMeetingsResponse.next_page_token === "") {
        setNextPageToken("none");
      } else {
        setNextPageToken(getMeetingsResponse.next_page_token);
      }

      const getSettingsResponse = data[1].object;
      setSettings(getSettingsResponse);

      const getUserResponse = data[2].object;
      setUser(getUserResponse);
      window.analytics.identify(getUserResponse.email, {
        name: getUserResponse.name,
        email: getUserResponse.email,
      });
      window.analytics.page()
    }
    fetchData();
  }, []);

  const snackbarAlerts = () => {
    const handleClose = (event, reason) => {
      setSnackbarOpen(false);
    };
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          message={snackbarText}
        />
      </div>
    )
  }


  const getMeetings = async () => {
    if (nextPageToken === "none") {
      return;
    }
    const data = await Yobs.getMeetings(nextPageToken);
    const concatMeetings = meetings.concat(data.object.meetings);
    setMeetings(concatMeetings);
    if (data.object.next_page_token === "") {
      setNextPageToken("none");
    } else {
      setNextPageToken(data.object.next_page_token);
    }
  };

  const toggleYobsProcessing = async (switchStateBoolean, meetingId, index, params) => {
    if (isProcessing) {
      return;
    }
    if (switchStateBoolean) {
      if ([params.meetingType, params.jobFunction, params.jobRole].includes('')) {
        setSnackbarText('Missing required information');
        setSnackbarOpen(true);
        setIsProcessing(false);
        return;
      }
      setIsProcessing(true);
      const newMeetings = [...meetings];
      newMeetings[index].SHOULD_PROCESS = switchStateBoolean;
      setMeetings(newMeetings);
      await Yobs.enableProcessing(meetingId, params);
    } else {
      setIsProcessing(true);
      const newMeetings = [...meetings];
      newMeetings[index].SHOULD_PROCESS = switchStateBoolean;
      setMeetings(newMeetings);      
      await Yobs.disableProcessing(meetingId);
    }
    setSnackbarText('Call settings updated successfully');
    setSnackbarOpen(true);
    setIsProcessing(false);
  };

  const onSettingsChange = async (RECORD_ALL, SHARE_ALL, EMAIL_NOTIFICATION, VIDEO_LANGUAGE) => {
    setIsProcessing(true);
    const newSettings = {};
    if (typeof(RECORD_ALL) === 'boolean') {
      newSettings.RECORD_ALL = RECORD_ALL;
    }
    if (typeof(SHARE_ALL) === 'boolean') {
      newSettings.SHARE_ALL = SHARE_ALL;
    }
    if (typeof(EMAIL_NOTIFICATION) === 'boolean') {
      newSettings.EMAIL_NOTIFICATION = EMAIL_NOTIFICATION;
    }
    if (VIDEO_LANGUAGE) {
      newSettings.VIDEO_LANGUAGE = VIDEO_LANGUAGE;
    }
    const newSettingState = Object.assign({}, settings, newSettings);
    setSettings(newSettingState);
    await Yobs.updateSettings(newSettings);
    setNextPageToken(null);
    const refreshMeetings = await Yobs.getMeetings();
    setMeetings(refreshMeetings.object.meetings);
    if (refreshMeetings.object.next_page_token === "") {
      setNextPageToken("none");
    } else {
      setNextPageToken(refreshMeetings.object.next_page_token);
    }
    setSnackbarText('Account settings updated successfully');
    setSnackbarOpen(true);
    setIsProcessing(false);
  };
  const shouldShowMoreButton = (meetings[0] !== 'loading' && nextPageToken !== "none")
  return (
    <div>
      { snackbarAlerts() }
      <Grid container spacing={3} className="main-content">
        <Grid item xs={12} md={12} lg={12}>
          <h1 className="text-navy"> Upcoming calls </h1>
        </Grid>
        <Grid item xs={12} md={7} lg={8} className="main-content-container">
          <Paper className="background-initial"
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <UpcomingCalls data={meetings} toggleYobsProcessing={toggleYobsProcessing}/>
          </Paper>
          {
            shouldShowMoreButton && (
              <Button onClick={() => getMeetings()} sx={{ mt: 3 }}>
                See more upcoming calls
              </Button>
            )
          }
        </Grid>
        <Grid item xs={12} md={5} lg={4}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 240,
            }}
          >
            <Settings data={settings} onChange={onSettingsChange} user={user} isProcessing={isProcessing}/>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default function Overview() {
  return <OverviewContent />;
}