const parseIsoDateTime = (isoDateTime, duration) => {
  if (!isoDateTime) {
    return "Reccuring";
  }

  // Example input format: 2021-09-02T01:00:00Z
  const dateToday = new Date();
  const dd = String(dateToday.getDate()).padStart(2, '0');
  const mm = String(dateToday.getMonth() + 1).padStart(2, '0');
  const yyyy = dateToday.getFullYear();
  const today = `${yyyy}-${mm}-${dd}`;

  const startTime = new Date(isoDateTime).toLocaleString()
  // Example startTime: '9/1/2021, 6:00:00 PM'
  const endTime = new Date(new Date(isoDateTime).getTime() + duration*60000).toLocaleString().split(',')[1]
  // Example endTime: 6:00:00 PM'

  if (isoDateTime.split(',')[0] === today) {
    return `Today | ${isoDateTime.split(',')[1]} - ${endTime}`;
  }
  return `${startTime.replace(',',' |')} - ${endTime}`;
};

const isoDateToHumanReadable = (isoDateTime) => {
  return new Date(isoDateTime).toString().split(' ').slice(0,4).join(' ');
}

const isoDateToLocalTime = (isoDateTime) => {
  return new Date(isoDateTime).toString().split(' ').slice(4,6).join(' ');
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

function meetingFunctionMap(string) {
  const map = {
    hiring: "Interview",
    talentDevelopment: "1:1",
    "Sales": "Sales",
    "Engineering": "Engineering",
    "Design": "Design",
    "Product": "Product",
    "Operations": "Operations",
    "Marketing": "Marketing",
    "Management": "Management",
    "Other": "Other"
  }
  return map[string];
}

function meetingRoleMap(string) {
  const map = {
    "Business Analyst": "Business Analyst",
    "Data Analyst": "Data Analyst",
    "Project Manager": "Project Manager",
    "Attorney": "Attorney",
    "CEO": "CEO",
    "CFO": "CFO",
    "CMO": "CMO",
    "COO": "COO",
    "CTO": "CTO",
    "Growth Hacker": "Growth Hacker",
    "Marketing Manager": "Marketing Manager",
    "Content Creator": "Content Creator",
    "Social Media Manager": "Social Media Manager",
    "Copywriter": "Copywriter",
    "Digital Marketing Manager": "Digital Marketing Manager",
    "Product Marketing Manager": "Product Marketing Manager",
    "Finance/Accounting": "Finance/Accounting",
    "H.R.": "H.R.",
    "Office Manager": "Office Manager",
    "Recruiter": "Recruiter",
    "Customer Service": "Customer Service",
    "Operations Manager": "Operations Manager",
    "Chief of Staff": "Chief of Staff",
    "Product Manager": "Product Manager",
    "User Researcher": "User Researcher",
    "Visual Designer": "Visual Designer",
    "Creative Director": "Creative Director",
    "Product Designer": "Product Designer",
    "Graphic Designer": "Graphic Designer",
    "Design Manager": "Design Manager",
    "Software Engineer": "Software Engineer",
    "DevOps": "DevOps",
    "Hardware Engineer": "Hardware Engineer",
    "Mobile Engineer": "Mobile Engineer",
    "Frontend Engineer": "Frontend Engineer",
    "Machine Learning Engineer": "Machine Learning Engineer",
    "Android Developer": "Android Developer",
    "Engineering Manager": "Engineering Manager",
    "QA Engineer": "QA Engineer",
    "Mechanical Engineer": "Mechanical Engineer",
    "Full-Stack Engineer": "Full-Stack Engineer",
    "Data Engineer": "Data Engineer",
    "Security Engineer": "Security Engineer",
    "Data Scientist": "Data Scientist",
    "Software Architect": "Software Architect",
    "Systems Engineer": "Systems Engineer",
    "Backend Engineer": "Backend Engineer",
    "Embedded Engineer": "Embedded Engineer",
    "iOS Developer": "iOS Developer",
    "Sales Development Rep": "Sales Development Rep",
    "Sales Manager": "Sales Manager",
    "Business Development Manager": "Business Development Manager",
    "Account Executive": "Account Executive",
    "Customer Success Manager": "Customer Success Manager"
  }
  return map[string];
}

const _exports = {capitalizeFirstLetter, copyTextToClipboard, isoDateToHumanReadable, isoDateToLocalTime, parseIsoDateTime, meetingFunctionMap, meetingRoleMap};
export default _exports;
